.container {
  max-width: 1725px;
  margin: 0 auto;
  padding-inline: 183px;
}

.yellow_linear {
  background: linear-gradient(273.91deg, #f6b60b 43.44%, #f5cb5c 223.7%);
}

.yellow_linear400 {
  background: linear-gradient(190.55deg, #f5cb5c 9.42%, #f6b60b 125.17%);
}

.gray_linear {
  background: linear-gradient(
    273.91deg,
    #919191 43.44%,
    #ffffff 191.78%,
    #a6a6a6 223.7%
  );
}

.light_shadow {
  box-shadow: 0px 0px 4px 0px #00000040;
}
.light_shadow400 {
  box-shadow: 0px 4px 26px 0px #00000040;
}
