@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&family=Zeyada&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Khula:wght@300;400;600;700;800&display=swap");
@import "tippy.js/dist/tippy.css";
@import "tippy.js/themes/light.css";
@import "react-circular-progressbar/dist/styles.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: Poppins;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

#root {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"] {
  outline: none !important;
  outline-offset: 0;
  border: none !important;
  box-shadow: none !important;
}

input.report-name {
  outline: 1px solid #00000040 !important;
}

input.report-name {
  outline-offset: initial;
}

input.input-fill {
  outline: 1px solid #00000040 !important;
  box-shadow: 0px 0px 8px 0px #00000040 !important;
}

input.input-fill:focus {
  outline-offset: initial;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: inherit !important;
  background-image: none !important;
  background-color: transparent !important;
  transition: all 5000s ease-in-out 0s; /* A hack to override autofill background changes */
  color: inherit !important;
  font-size: inherit !important;
  font-weight: inherit !important;
}

/* .dropdown-content .panel-content {
  overflow-y: scroll;
}

.panel-content .select-panel ul {
  max-height: 380px;
}

.select-panel ul li label div.item-renderer {
  align-items: center;
}

label div.item-renderer span {
  font-size: 13px;
} */

/* .hideScrollbar::-webkit-scrollbar {
  display: none !important;
} */

.report-filter-scroll::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.report-filter-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px black;
}

.report-filter-scroll::-webkit-scrollbar-thumb {
  background-color: black;
  outline: 1px solid black;
  border-radius: 60px;
}

.dashboard-table-scroll::-webkit-scrollbar {
  width: 1px;
  height: 2px;
}

.dashboard-table-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.dashboard-table-scroll::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 60px;
}

.report-result-scroll::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.report-result-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.report-result-scroll::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 60px;
}

.vendor-scroll::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.vendor-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.vendor-scroll::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 60px;
}

.table-scroll::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.table-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.table-scroll::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 60px;
}

.vendor-comparison::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.vendor-comparison::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.vendor-comparison::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 60px;
}

/* .vendor-comparison::-webkit-scrollbar-thumb {
  border: 5px solid transparent;
  border-radius: 100px;
  background-color: #8070d4;
  background-clip: content-box;
} */

/* .report-table {
  white-space: nowrap;
  margin: 0;
  border: none;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
}
.report-table td,
.report-table th {
  padding: 0.5rem 1rem;
}
.report-table thead th {
  padding: 10px 20px;
  position: sticky;
  top: 0;
  z-index: 1;
  width: 25vw;
  background: white;
}
.report-table td {
  background: #fff;
  padding: 10px 20px;
  text-align: center;
}

.report-table tbody th {
  font-weight: 100;
  text-align: left;
  position: relative;
  padding-left: 40px;
  padding-right: 40px;
}
.report-table thead th:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
}
.report-table tbody th {
  position: sticky;
  left: 0;
  background: white;
  z-index: 1;
}
.report-table tr:nth-child(even) td {
  background-color: #faf9f9;
}

.report-table thead tr th:first-child,
.report-table tbody tr td:first-child {
  width: 8em;
  min-width: 8em;
  max-width: 8em;
  word-break: break-all;
} */
/* [role="region"][aria-labelledby][tabindex] {
  width: 100%;
  max-height: 98vh;
  overflow: auto;
}
[role="region"][aria-labelledby][tabindex]:focus {
  box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5);
  outline: 0;
} */

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  padding: 4px 8px;
  position: relative;
  display: block;
  color: black;
  text-decoration: none;
  font-weight: bold;
  background-color: #fff;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 3;
  color: black;
  border-bottom: 2px solid #525252;
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}

.page-item:hover .page-link {
  color: #f5cb5c;
}

.vscomp-option-text {
  overflow: auto !important;
  text-overflow: unset !important;
  white-space: wrap !important;
}

.vscomp-wrapper.has-clear-button .vscomp-toggle-button {
  border-radius: 8px;
  height: 38px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}

.vscomp-dropbox-container {
  border-radius: 4px;
}

.vscomp-dropbox {
  border-radius: 8px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}

.vscomp-option {
  display: flex !important;
  height: 200% !important;
  min-height: 51px !important;
  font-size: 14px !important;
  line-height: 112.5% !important;
  font-weight: 400 !important;
  color: #333333 !important;
  flex-wrap: unset !important;
}

/* .vscomp-wrapper .checkbox-icon {
  width: 17px !important;
  height: 17px !important;
}

.vscomp-wrapper .checkbox-icon::after {
  border: 1px solid #000000 !important;
  border-radius: 3px;
}

.vscomp-option.selected .checkbox-icon {
  background-color: #333333 !important;
  border-radius: 3px;
  padding: 2px;
  width: 17px !important;
  height: 17px !important;
} */

/* .vscomp-wrapper.multiple .vscomp-option.selected .checkbox-icon::after {
  content: url(/public/icons/check.svg);
  transform: none !important;
  border-color: #333333 !important;
} */

.vscomp-toggle-button {
  padding-left: 16px !important;
}

.vscomp-arrow {
  right: 18px !important;
}

.vscomp-arrow::after {
  content: url(/public/icons/arrow-down.svg)!important;
  transform: translate(18px, 22%) !important;
  width: 15px !important;
  height: 15px !important;
  border: unset !important;
}

/* .vscomp-clear-button {
  right: 48px !important;
} */


.dashboard-table {
  width: 100%;
  border-collapse: collapse;
}

.dashboard-th, .dashboard-td {
  padding: 8px;
}

.dashboard-th {
  background-color: #F9F9FC;
}

.vscomp-wrapper .checkbox-icon::after {
  content: url(/public/icons/empty-check.svg)!important;
  top: -5px!important;
  left: -5px!important;
  border: unset!important;
  position: absolute!important;
  z-index: 1!important;
}

/* custom checkbox style for dropdown */
.vscomp-wrapper.multiple .vscomp-option.selected .checkbox-icon::after {
  display: block!important;
  position: absolute!important;
  top: -5px!important;
  left: -5px!important;
  box-sizing: border-box!important;
  transform: initial!important;
  border-color: initial!important;
  border-left-color: initial!important;
  border-top-color: initial!important;
  border: initial!important;
  content: url(/public/icons/checked.svg)!important;
  z-index: 2!important;
}

.checkbox-icon .vscomp-toggle-all-checkbox::after {
  content: url(/public/icons/empty-check.svg)!important;
  top: -5px!important;
  left: -5px!important;
  border: unset!important;
  position: absolute!important;
  z-index: 1!important;
}

.vscomp-wrapper .checkbox-icon.checked::after {
  transform: unset!important;
  content: url(/public/icons/checked.svg)!important;
  top: -5px!important;
  left: -5px!important;
  border: unset!important;
  position: absolute!important;
  z-index: 1!important;
}

.vscomp-options-container::-webkit-scrollbar {
  width: 20px;
  height: 4px;
}

.vscomp-options-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
}

.vscomp-options-container::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 5px;
}

.report-builder-item {
  overflow: revert;
}

/*Report builder table*/
.rb-table {
  width: 100%!important;
  border-collapse: collapse!important;
  text-align: center!important;
}

.rb-th, .rb-td {
  padding: 8px!important;
  border-bottom: 1px solid #E5E5E5!important;
}

.rb-th.sub-category {
  font-weight: normal;
}
.rb-th.category:not(:last-child) {
  border-right: 1px solid #E5E5E5!important;
}

.rb-th.category:first-child {
  border-left: 1px solid #E5E5E5!important;
}

.rb-th.vendor:not(:last-child), .rb-th.name:not(:last-child) {
  border-right: 1px solid #E5E5E5!important;
}

.rb-tr .sub-category {
  background-color: #F9F9FC!important;
}

.rb-tbody .rb-td {
  background-color: #F9F9FC!important;
}

.rb-thead .rb-th {
  position: sticky!important;
  top: 0!important;
}

.rb-table-scroll::-webkit-scrollbar {
  width: 1px;
  height: 2px;
}

.rb-table-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.rb-table-scroll::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 60px;
}

.rb-table tbody .rb-tr:nth-child(even) {
  background-color: #F9F9FC;
}

.sr-table {
  width: unset!important;
}

.sr-th {
  padding-right: 24px!important;
  border-bottom: 1px solid #E5E5E5!important;
}

.sr-th:not(.sr-th-title) {
  font-weight: normal!important;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

.payment-confirmation__modal {
  background: transparent!important;
  max-width: initial!important;
  padding: 0!important;
}

.payment-confirmation__modal-btn {
  display: none!important;
}

.column-shrinked {
 padding: 0!important; 
}

.rb-th.sr-th.sr-th-title.ifAnyShrinked {
  padding: 0!important;
}

.filter-vendor-list-by-category-dropdown .vscomp-options-container {
  max-height: 420px!important;
}

.save-report-modal-scroll::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.save-report-modal-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}


.founded-modal-scroll::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.founded-modal-scroll::-webkit-scrollbar-track {
  background: #f5f5f5;
  border-radius: 10px;
}

.founded-modal-scroll::-webkit-scrollbar-thumb {
  background: #b0b0b0;
  border-radius: 10px;
}

.founded-modal-scroll::-webkit-scrollbar-thumb:hover {
  background: #909090;
}


.custom-input .vscomp-wrapper,
.custom-input .vscomp-toggle-button,
.custom-input .vscomp-dropdown {
  border: none !important;
  box-shadow: none !important;
  width: 175px;
  font-size: small;
  color: inherit;
  
}

.custom-input .vscomp-toggle-button {
  padding: 0 !important; /* Remove padding */
}

.custom-input .vscomp-wrapper input {
  width: calc(100% - 30px); /* Adjust width to ensure text is fully visible */
  padding-right: 1.5rem; /* Adjust padding to ensure text is visible */
  box-sizing: border-box; /* Ensure padding is included in width calculation */
}

.custom-input .vscomp-wrapper .vscomp-toggle-button .vscomp-value {
  color:  #87a5c2 !important; /* Change this to your desired text color */
}

.custom-input .vscomp-arrow {
  display: none !important;
}

.custom-input .vscomp-dropbox-container {
  box-shadow: initial !important;
  top: -47.1px;
}

.custom-input .vscomp-dropbox {
  box-shadow: initial !important;
  position: relative;
  top: -30.25px;
}

.custom-input .vscomp-search-container {
  border-color: #00a8e8  !important;
  border-bottom-width: 2px !important;
  width:190px;
  position:relative;
  right:13px;
  top:-0.5px;
  animation: none;
}

.custom-input .vscomp-options-container::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.custom-input .vscomp-options-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
}

.custom-input .vscomp-options-container::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 5px;
}

.custom-input .vscomp-no-search-results {
  display: none !important;
}

.custom-input .pop-comp-active{
  transition: none;
}

.custom-style .virtual-select__control {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: none;
  padding: 0.5rem;
  cursor: pointer;
}

.custom-style .virtual-select__menu {
  border-radius: 4px;
  box-shadow: 0 4px 11px rgba(0, 0, 0, 0.1);
}

.custom-style .virtual-select__option {
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
}

.custom-style .virtual-select__option--is-selected {
  background-color: #e0ffe0;
  color: black;
}

.custom-style .virtual-select__option--is-focused {
  background-color: #f0f0f0;
}

.custom-style .virtual-select__option input {
  margin-right: 0.5rem;
}
